import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: UserObjectProps = {
  id: 0,
  name: "",
};

type UserObjectProps = {
  id: number;
  name?: string;
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserObjectProps>) => {
      console.log("User State: ", state, " action.payload: ", action.payload);
      window.sessionStorage.setItem(
        "clientId",
        JSON.stringify(action.payload.id)
      );
      return { ...state, id: action.payload.id, name: action.payload.name };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
