import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import Konva from "konva";

interface initialStateProps {
  konvaLayer: Konva.Layer | {};
  konvaStage: Konva.Stage | {};
}
const initialState: initialStateProps = {
  konvaLayer: {},
  konvaStage: {},
};

export const gridSlice = createSlice({
  name: "grid",
  initialState,
  reducers: {
    setLayer: (state, action: PayloadAction<Konva.Layer>) => {
      console.log("payload action: ", action.payload);
      state.konvaLayer = action.payload;
    },
    setStage: (state, action: PayloadAction<Konva.Stage>) => {
      console.log("payload action: ", action.payload);
      state.konvaStage = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLayer, setStage } = gridSlice.actions;

export default gridSlice.reducer;
