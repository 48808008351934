import Konva from "konva";
import React, { useRef } from "react";
import { Group, Line, Shape } from "react-konva";
import { CustomPositionProps } from "./DancerObject";
import { setDanceArray } from "../slices/danceSlice";
import { useDispatch, useSelector } from "react-redux";
import { TDancerShape } from "./ShapeTypes";
import { KonvaEventObject } from "konva/lib/Node";

export const DancerShape = ({
  isDrawing,
  posX,
  posY,
  gender,
  index,
  blockSnapSize,
  layerRef,
  transfomerRef,
  rotation,
}: TDancerShape) => {
  const shapeRef = useRef(null);
  const dispatch = useDispatch();
  var state = useSelector((state: any) => state.dance);
  var selectedScene = state.selectedScene;
  var sceneList = state.current.sceneList;
  var selectedSceneObject = sceneList[selectedScene];

  const handleTransformShape = (
    event: Konva.KonvaEventObject<DragEvent | Event>
  ) => {
    const target = event.target;
    console.log("target ", target);

    const updatedList = selectedSceneObject.positionList?.map(
      (position: CustomPositionProps, index: number) => {
        console.log(index, target.attrs.id);
        if (index === parseInt(target.attrs.id)) {
          console.log("Juup ", target.attrs.rotation);
          const updatedPosition = {
            ...position,
            posX: event.target.attrs.x,
            posY: event.target.attrs.y,
            rotation: event.target.attrs.rotation,
          };
          console.log(updatedPosition);
          position = updatedPosition;
        }
        return position;
      }
    );

    dispatch(setDanceArray(updatedList));
  };

  const onClickEvent = (e: any) => {
    const target = e.target;
    transfomerRef?.current?.nodes([target]);
  };

  return (
    <>
      {gender === "MIXED" ? (
        <Group
          x={posX}
          y={posY}
          onClick={onClickEvent}
          onTap={onClickEvent}
          onDragStart={(e: any) => {
            e.target.moveToTop();
          }}
          onDragEnd={(e: any) => {
            e.target.position({
              x: Math.round(e.target.x() / blockSnapSize) * blockSnapSize,
              y: Math.round(e.target.y() / blockSnapSize) * blockSnapSize,
            });
            e.target.rotation(e.target.attrs.rotation);
            layerRef?.current?.batchDraw();
            handleTransformShape(e);
          }}
          onDragMove={(e: any) => {
            layerRef?.current?.batchDraw();
          }}
          onTransformEnd={(e: KonvaEventObject<Event>) => {
            handleTransformShape(e);
          }}
          draggable={!isDrawing}
          ref={shapeRef}
          id={JSON.stringify(index)}
          key={index}
          width={80}
          height={80}
        >
          <Line
            rotation={rotation}
            points={[-20, 40, 0, 0, 20, 40]}
            tension={0}
            hitStrokeWidth={20}
            lineJoin="round"
            strokeHitEnabled
            lineCap="round"
            stroke={"black"}
            fill="green"
            strokeWidth={3}
            width={40}
            height={40}
            fillEnabled
          />
          <Line
            x={20}
            y={0}
            rotation={rotation}
            points={[0, 0, 20, 40, 40, 0]}
            tension={1.5}
            hitStrokeWidth={40}
            lineJoin="round"
            strokeHitEnabled
            lineCap="round"
            stroke={"black"}
            fill="green"
            strokeWidth={3}
            width={45}
            height={45}
            fillEnabled
          />
        </Group>
      ) : (
        <Line
          draggable={!isDrawing}
          x={posX}
          y={posY}
          ref={shapeRef}
          rotation={rotation || (gender === "MALE" ? 180 : undefined)}
          points={
            gender === "FEMALE"
              ? [-20, 40, 0, 0, 20, 40]
              : [0, 0, 20, 40, 40, 0]
          }
          tension={gender === "FEMALE" ? 0 : 1.5}
          hitStrokeWidth={40}
          lineJoin="round"
          lineCap="round"
          stroke={"black"}
          fill="green"
          strokeWidth={3}
          width={40}
          height={40}
          key={index}
          id={JSON.stringify(index)}
          onClick={onClickEvent}
          onTap={onClickEvent}
          onDragStart={(e: any) => {
            e.target.moveToTop();
          }}
          onDragEnd={(e: any) => {
            e.target.position({
              x: Math.round(e.target.x() / blockSnapSize) * blockSnapSize,
              y: Math.round(e.target.y() / blockSnapSize) * blockSnapSize,
            });
            e.target.rotation(e.target.attrs.rotation);
            layerRef?.current?.batchDraw();
            handleTransformShape(e);
          }}
          onDragMove={(e: any) => {
            layerRef?.current?.batchDraw();
          }}
          onTransformEnd={(e: KonvaEventObject<Event>) => {
            handleTransformShape(e);
          }}
          fillEnabled
        />
      )}
    </>
  );
};

export default DancerShape;
