import { Pagination } from "@mui/material";

export interface PaginationProps {
  count: number;
  onClick?: React.FunctionComponent;
}

const PaginationComponent = ({ count, onClick }: PaginationProps) => {
  return <Pagination count={count} onClick={onClick} sx={{marginTop: "40px"}} color="secondary" variant="outlined" />;
};

export default PaginationComponent;
