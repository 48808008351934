import {
  Box,
  Button,
  Divider,
  Fade,
  Grid,
  IconButton,
  Popper,
  Stack,
  ToggleButton,
} from "@mui/material";
import "../styles/Grid.scss";
import React, { useEffect, useRef, useState } from "react";
import Carousel from "../components/Carousel";
import SideMenu from "../components/SideMenu";
import Controls from "../components/Controls";
import ActionButton from "../components/ActionButton";
import { useDispatch, useSelector } from "react-redux";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import {
  resetState,
  setDance,
  setObjectCounter,
  addDanceElem,
  setIsDrawing,
} from "../slices/danceSlice";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { GridOffOutlined, GridOnOutlined } from "@mui/icons-material";
import DescriptionDrawer from "../components/DescriptionDrawer";
import { detectUrl } from "../helpers/helpers";
import { updateCurrentDance } from "../controllers/CreateDanceController";
import SaveIcon from "@mui/icons-material/Save";
import DescriptionIcon from "@mui/icons-material/Description";
import WcIcon from "@mui/icons-material/Wc";
import BrushIcon from "@mui/icons-material/Brush";
import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";
import { DanceApi, headers } from "../controllers/Endpoints";
import { IPositionProps, SceneProps } from "../types/DanceTypes";
import GridCanvas from "../components/Grid";
import Konva from "konva";
import "../styles/CreateDance.scss";
import SettingsIcon from "@mui/icons-material/Settings";
import PrintIcon from "@mui/icons-material/Print";
import DashedLine from "../assets/dashedLine";
import FemaleDancer from "../assets/FemaleDancer";
import CallMadeRoundedIcon from "@mui/icons-material/CallMadeRounded";
import RedoRoundedIcon from "@mui/icons-material/RedoRounded";
import { DrawingTypes } from "../types/ShapeTypes";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import MaleDancer from "../assets/MaleDancer";
import MixedDancers from "../assets/MixedDancers";

const CreateDance = () => {
  const { t } = useTranslation();

  const [elements, setElements] = useState<JSX.Element[]>([]);
  const [danceBlocks, setDanceBlocks] = useState<SceneProps[]>([]);
  const [stageDimensions, setStageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [grid, setGrid] = useState(true);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [danceState, setDanceState] = useState({});
  const [isDanceDetailsOpen, setIsDanceDetailsOpen] = useState(false);
  const [isBrushesMenuOpen, setIsBrushesMenuOpen] = useState(false);
  const [lines, setLines] = React.useState([]);

  var constraintsRef = useRef<HTMLDivElement>(null);
  var width = 0;
  var height = 0;

  var uid = Date.now().toString(36) + Math.random().toString(36).substr(2);
  var sceneList = useSelector((state: any) => state.dance.current.sceneList);
  var state = useSelector((state: any) => state.dance.current);
  var drawingState = useSelector((state: any) => state.dance.drawingProps);
  var objectCounter = useSelector((state: any) => state.dance.objectCounter);
  const layerRef = useRef<Konva.Layer>(null);
  const gridLayerRef = useRef<Konva.Layer>(null);
  const gridStageRef = useRef<Konva.Stage | null>(null);
  //const konvaLib = useKonvaLib(gridLayerRef, gridStageRef, width, height);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [danceDataEl, setDanceDataEl] = React.useState<null | HTMLElement>(
    null
  );
  const [brushesMenuEl, setBrushesMenuEl] = React.useState<null | HTMLElement>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setCarouselOpen((previousOpen) => !previousOpen);
  };
  const handleDanceDetailsClick = (event: React.MouseEvent<HTMLElement>) => {
    //setDanceDataEl(event.currentTarget);
    setIsDanceDetailsOpen((previousOpen) => !previousOpen);
  };

  const handleAddElement = async (
    gender: "MALE" | "FEMALE" | "MIXED",
    posX: number,
    posY: number,
    fromApi: boolean,
    counter: number
  ) => {
    const newObj = {
      id: counter,
      gender: gender,
      posY: posY,
      posX: posX,
      position: 0,
    };

    if (!fromApi) {
      dispatch(setObjectCounter(counter + 1));
    }

    dispatch(addDanceElem(newObj));
    setTimeout(function () {}, 2000);
  };

  const handleAddDanceBlock = (blocks: SceneProps) => {
    setDanceBlocks((prevObj) => [...prevObj, blocks]);
  };

  const getDanceData = async () => {
    const query = window.location.search.split("=")[1];
    const urlParams = new URLSearchParams(query);
    const url = detectUrl + DanceApi.GET_DANCE + query;
    var danceId = null;

    if (urlParams) {
      const response = await axios.get(url, { headers });

      const data = response.data;
      danceId = data.id;
      for (let i = 0; i < data?.sceneList?.length; i++) {
        for (let j = 0; j < data?.sceneList[i].positionList.length; j++) {
          data.sceneList[i].positionList[j].id = j;
        }
      }
      dispatch(setDance(data));
      const sceneList = data.sceneList;
      let counter = 0;
      sceneList[0]?.positionList.map((obj: IPositionProps) => {
        handleAddElement(obj.gender, obj.posX, obj.posY, true, counter);
        counter += 1;
      });

      sceneList?.forEach((obj: any) => {
        handleAddDanceBlock(obj);
      });

      //set global counter
      dispatch(setObjectCounter(counter));
      setDanceState(data);
    }
  };

  const accountId = window.sessionStorage?.getItem("clientId");

  let newSceneList = { ...sceneList };

  const body = {
    name: state.name,
    accountId: parseInt(accountId!),
    author: state.author,
    music: state.music,
    source: state.source,
    danceType: "ORIGINAL_DANCE",
    describer: state.describer,
    gatheredDate: 2023,
    createdDate: 2023,
    systemCreatedDate: 22323,
    systemEditedDate: 1233231,
    visiblility: "PUBLIC",
    groupType: "MIXED",
    sceneList: newSceneList,
    danceDescription: {
      text: "description",
    },
  };
  interface TempObject {
    id?: number;
    posX: number;
    gender: string;
    posY: number;
  }

  /* TO-DO, update current dance after saving for the first time*/

  const saveCurrentPositions = () => {
    setIsError(false);
    setIsLoading(true);

    let newSceneList = sceneList.map((scene: SceneProps) => ({
      ...scene,
      positionList: scene.positionList.map((position) => {
        const { id, ...rest } = position;
        return rest;
      }),
    }));

    console.log("new scene list ", newSceneList);
    let filteredBody = {
      ...body,
      sceneList: newSceneList,
    };

    axios
      .post(detectUrl + DanceApi.SAVE, filteredBody, {
        headers: headers,
      })
      .then((res) => {
        setIsError(false);
        setIsLoading(false);
        console.log("tants edukalt salvestatud ", res);
      })
      .catch((e) => {
        setIsError(true);
        setIsLoading(false);
        console.log(e);
      });
  };

  const handleSideMenuChanges = (fieldName: string, value: string) => {
    switch (fieldName) {
      case "name":
        body.name = value;
        dispatch(setDance({ ...state, name: value }));
        break;
      case "author":
        body.author = value;
        dispatch(setDance({ ...state, author: value }));
        break;
      case "music":
        body.music = value;
        dispatch(setDance({ ...state, music: value }));
        break;
      case "source":
        body.source = value;
        dispatch(setDance({ ...state, source: value }));
        break;
      default:
        break;
    }
  };

  const toggleGrid = () => {
    setGrid(!grid);
    const elem = document.querySelector(".dance-grid") as HTMLElement;
    if (grid) {
      elem.classList.add("hide-grid");
    } else {
      elem.classList.remove("hide-grid");
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const toggleIsDrawing = (type: DrawingTypes) => {
    dispatch(
      setIsDrawing({
        isDrawing: !drawingState.isDrawing,
        type: type,
      })
    );
  };

  const handleOpenBrushes = () => {
    setIsBrushesMenuOpen((previousOpen) => !previousOpen);
  };
  const deleteShape = (e: Konva.KonvaEventObject<Event>) => {
    e.currentTarget.destroy();
  };

  useEffect(function () {
    const grid = document.querySelector(".dance-grid") as HTMLElement;
    setStageDimensions({
      width: window.innerWidth,
      height: window.innerHeight - 60,
    });

    return () => {
      window.localStorage.removeItem("currentDance");
      dispatch(resetState);
    };
  }, []);

  return (
    <>
      <Grid item className="main-content">
        <Stack alignItems={"center"}>
          <div id="stage-container">
            <GridCanvas
              width={stageDimensions.width}
              height={stageDimensions.height}
              layerRef={layerRef}
              gridStageRef={gridStageRef}
              ref={gridStageRef}
              gridSize={20}
            />
          </div>
          <Stack alignItems={"end"}>
            <DescriptionDrawer open={open} onClose={toggleDrawer} />
          </Stack>
          <Grid
            container
            direction={"row"}
            flexWrap={"wrap"}
            className="side-menu secondary-content"
            padding={undefined}
          >
            <Grid
              item
              direction={"column"}
              sx={{ flexWrap: "nowrap" }}
              padding={undefined}
              justifyItems="center"
              ref={setDanceDataEl}
            >
              <Grid item rowSpacing={1}>
                <ActionButton
                  variant={"contained"}
                  text={""}
                  icon={<SettingsIcon />}
                  onClick={handleDanceDetailsClick}
                />
                <Popper
                  keepMounted
                  id={"popper"}
                  open={isDanceDetailsOpen}
                  anchorEl={danceDataEl}
                  transition
                  placement="top-start"
                >
                  {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                      <Box
                        sx={{ border: 1, p: 1, bgcolor: "background.paper" }}
                        className="meta-data__container"
                      >
                        <SideMenu
                          name={body.name}
                          author={body.author}
                          music={body.music}
                          onChange={handleSideMenuChanges}
                        />
                      </Box>
                    </Fade>
                  )}
                </Popper>
              </Grid>
              <Divider orientation="horizontal" />
              <Grid item>
                <ActionButton
                  variant={"contained"}
                  text={""}
                  icon={<PrintIcon />}
                  id="printCanvas"
                />
              </Grid>
            </Grid>

            <Divider
              orientation="vertical"
              sx={{ backgroundColor: "black" }}
              flexItem
            ></Divider>

            <Controls
              alignTitle={{ textAlign: "right" }}
              sx={{ marginLeft: "auto" }}
              className="controls--buttons"
            >
              <ActionButton
                variant={"contained"}
                text={""}
                isLoading={isLoading}
                onClick={() =>
                  state.currentDanceId != null
                    ? updateCurrentDance(state.currentDanceId, state.current)
                    : saveCurrentPositions()
                }
                icon={<SaveIcon />}
              />
              <ActionButton
                variant={"outlined"}
                text={""}
                onClick={toggleGrid}
                icon={grid ? <GridOnOutlined /> : <GridOffOutlined />}
              />
              <ActionButton
                variant={"outlined"}
                text={""}
                onClick={() =>
                  handleAddElement(
                    "MALE",
                    stageDimensions.width / 2,
                    stageDimensions.height / 2,
                    false,
                    objectCounter
                  )
                }
                icon={<MaleDancer />}
              />
              <ActionButton
                variant="outlined"
                text={""}
                onClick={() =>
                  handleAddElement(
                    "FEMALE",
                    stageDimensions.width / 2,
                    stageDimensions.height / 2,
                    false,
                    objectCounter
                  )
                }
                //icon={<WomanIcon />}
                icon={<FemaleDancer />}
              />
              <ActionButton
                variant="outlined"
                text={""}
                onClick={() =>
                  handleAddElement(
                    "MIXED",
                    stageDimensions.width / 2,
                    stageDimensions.height / 2,
                    false,
                    objectCounter
                  )
                }
                icon={<MixedDancers />}
              />

              <IconButton
                onClick={handleOpenBrushes}
                ref={setBrushesMenuEl}
                color="primary"
              >
                <BrushIcon />
              </IconButton>
              <ToggleButton
                ref={setBrushesMenuEl}
                color="error"
                value="false"
                selected={
                  drawingState.isDrawing &&
                  drawingState.type === DrawingTypes.DELETE
                }
                onChange={() => {
                  dispatch(
                    setIsDrawing({
                      isDrawing: !drawingState.isDrawing,
                      type: DrawingTypes.DELETE,
                    })
                  );
                }}
              >
                <DeleteForeverOutlinedIcon />
              </ToggleButton>

              <Popper
                keepMounted
                id={"brushes-popper"}
                open={isBrushesMenuOpen}
                anchorEl={brushesMenuEl}
                transition
                placement="top"
                sx={{ margin: 20 }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps}>
                    <Box
                      sx={{
                        border: 1,
                        p: 1,
                        bgcolor: "background.paper",
                      }}
                    >
                      <ToggleButton
                        value={false}
                        selected={
                          drawingState.isDrawing &&
                          drawingState.type === DrawingTypes.PEN
                        }
                        size="small"
                        color="standard"
                        onChange={() => toggleIsDrawing(DrawingTypes.PEN)}
                      >
                        <BrushIcon />
                      </ToggleButton>
                      <ToggleButton
                        value={false}
                        color="standard"
                        selected={
                          drawingState.isDrawing &&
                          drawingState.type === DrawingTypes.DASH
                        }
                        size="small"
                        onChange={() => toggleIsDrawing(DrawingTypes.DASH)}
                      >
                        <DashedLine />
                      </ToggleButton>
                      <ToggleButton
                        value={false}
                        color="standard"
                        selected={
                          drawingState.isDrawing &&
                          drawingState.type === DrawingTypes.ROUNDED_ARROW
                        }
                        size="small"
                        onChange={() =>
                          toggleIsDrawing(DrawingTypes.ROUNDED_ARROW)
                        }
                      >
                        <RedoRoundedIcon />
                      </ToggleButton>
                      <ToggleButton
                        value={false}
                        color="standard"
                        selected={
                          drawingState.isDrawing &&
                          drawingState.type === DrawingTypes.ARROW
                        }
                        size="small"
                        onChange={() => toggleIsDrawing(DrawingTypes.ARROW)}
                      >
                        <CallMadeRoundedIcon />
                      </ToggleButton>
                      <ToggleButton
                        value={false}
                        color="standard"
                        size="small"
                        selected={
                          drawingState.isDrawing &&
                          drawingState.type === DrawingTypes.CIRCLE
                        }
                        onChange={() => toggleIsDrawing(DrawingTypes.CIRCLE)}
                      >
                        <CircleOutlinedIcon />
                      </ToggleButton>
                    </Box>
                  </Fade>
                )}
              </Popper>
              <ActionButton
                variant={"outlined"}
                text={t("DANCE.DESCRIPTION")}
                onClick={() => toggleDrawer()}
                icon={<DescriptionIcon />}
              />
              <ActionButton
                text="Joonised"
                variant="outlined"
                onClick={handleClick}
                icon={<LibraryAddOutlinedIcon />}
              />
            </Controls>

            <Popper
              keepMounted
              id={"popper"}
              open={carouselOpen}
              anchorEl={anchorEl}
              transition
              placement="top-end"
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Box
                    sx={{ border: 1, p: 1, bgcolor: "background.paper" }}
                    className="carousel-container"
                  >
                    <Carousel
                      blocks={danceBlocks}
                      setBlocks={handleAddDanceBlock}
                    />
                  </Box>
                </Fade>
              )}
            </Popper>
          </Grid>
        </Stack>
      </Grid>
    </>
  );
};

export default CreateDance;
