import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  Layer,
  Stage,
  Line,
  Group,
  Transformer,
  Rect,
  Circle,
  Arrow,
} from "react-konva";
import Konva from "konva";
import { IPositionProps } from "../types/DanceTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  setDanceArray,
  setDrawingList,
  setIsDrawing,
} from "../slices/danceSlice";
import DancerShape from "./DancerShape";
import { v4 as uuidv4 } from "uuid";
import { DrawingTypes } from "../types/ShapeTypes";
interface IGridCanvas {
  width: number;
  height: number;
  gridSize: number;
  layerRef: React.RefObject<Konva.Layer> | null;
  gridStageRef: React.RefObject<Konva.Stage> | null;
}

interface IDancerObject extends Partial<IGridCanvas> {
  gender: "MALE" | "FEMALE" | "MIXED";
  x: number;
  y: number;
  layerRef: React.RefObject<Konva.Layer>;
  key: number;
}

var blockSnapSize = 20;

var shadowRectangle = new Konva.Circle({
  x: 0,
  y: 0,
  width: blockSnapSize * 2,
  height: blockSnapSize * 2,
  fill: "gray",
  opacity: 0.6,
  stroke: "#CF6412",
  strokeWidth: 3,
  dash: [10, 2],
});

const GridCanvas = forwardRef(({ width, height }: IGridCanvas, ref) => {
  //States
  const [verticalLines, setVerticalLines] = useState<any>([]);
  const [horizontalLines, setHorizontalLines] = useState<any>([]);
  const [lines, setLines] = useState<any>([]);
  const [circles, setCircles] = useState<any>([]);
  const [arrows, setArrows] = useState<any>([]);
  const [mousePointer, setPointer] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [drawingAction, setDrawingActions] = useState<DrawingTypes>(
    DrawingTypes.PEN
  );

  //Redux selectors
  const dispatch = useDispatch();
  var state = useSelector((state: any) => state.dance);
  var selectedScene = state.selectedScene;
  var sceneList = state.current.sceneList;
  var selectedSceneObject = sceneList[selectedScene];
  //Other
  let setInt = useRef<NodeJS.Timer>();
  const isDrawing = React.useRef<boolean>(false);
  const stageRef = React.useRef<Konva.Stage>(null);
  const layerRef = React.useRef<Konva.Layer>(null);
  const gridLayerRef = React.useRef<Konva.Layer>(null);
  const shapeRef = React.useRef<Konva.Line>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const currentShapeId = React.useRef<string>();

  const handleClickOnCanvas = (e: any) => {
    transformerRef?.current?.nodes([]);
  };

  const handlePointerMove = () => {
    console.log(isDrawing.current);

    if (!isDrawing.current) {
      return;
    }
    transformerRef?.current?.nodes([]);
    if (state.drawingProps.type === DrawingTypes.CIRCLE) {
      const stage = stageRef.current;

      setCircles((circles: any) =>
        circles.map((circle: any) => {
          console.log(currentShapeId.current, circle.id);

          if (circle.id === currentShapeId.current) {
            return {
              ...circle,
              radius:
                ((stage?.getPointerPosition()?.y! - circle.y) ** 2 +
                  (stage?.getPointerPosition()?.x! - circle.x) ** 2) **
                0.5,
            };
          }
          return circle;
        })
      );
    } else if (
      state.drawingProps.type === DrawingTypes.PEN ||
      state.drawingProps.type === DrawingTypes.DASH
    ) {
      if (lines.length > 0) {
        const stage = stageRef?.current?.getStage();
        const point = stage?.getPointerPosition();

        setLines((prevLines: any) => {
          return prevLines.map((line: any, index: number) => {
            if (index === prevLines.length - 1) {
              // This is the last line, update its points
              return {
                ...line,
                points: line.points.concat([point?.x, point?.y]),
                type: state.drawingProps.type,
                dash: state.drawingProps.type === DrawingTypes.DASH,
              };
            } else {
              return line;
            }
          });
        });
      }
      dispatch(setDrawingList(lines));
    } else if (state.drawingProps.type === DrawingTypes.ARROW) {
      const stage = stageRef.current;
      console.log(arrows);

      setArrows((arrows: any) =>
        arrows.map((arrow: any, index: number) => {
          console.log(arrow);
          if (arrow.id === currentShapeId.current) {
            return {
              ...arrow,
              points: [
                arrow.points[0],
                arrow.points[1],
                stage?.getPointerPosition()?.x,
                stage?.getPointerPosition()?.y,
              ],
            };
          }
          return arrow;
        })
      );
    }
  };

  const onPointerDown = (e: Konva.KonvaEventObject<Event>) => {
    if (state.drawingProps.isDrawing) {
      transformerRef?.current?.nodes([]);
      if (state.drawingProps.type === DrawingTypes.DELETE) {
        console.log(e.target.attrs);
        if (e.target.attrs.id === "grid-background") {
          return;
        }
        e.target.destroy();
        return;
      }
      isDrawing.current = true;

      if (state.drawingProps.type === DrawingTypes.CIRCLE) {
        const id = uuidv4();
        const stage = stageRef.current;
        currentShapeId.current = id;
        setCircles((prev: any) => [
          ...prev,
          {
            id: id,
            x: stage?.getPointerPosition()?.x,
            y: stage?.getPointerPosition()?.y,
          },
        ]);
      } else if (
        state.drawingProps.type === DrawingTypes.PEN ||
        state.drawingProps.type === DrawingTypes.DASH
      ) {
        dispatch(
          setIsDrawing({ isDrawing: true, type: state.drawingProps.type })
        );
        const pos = stageRef.current?.getPointerPosition();
        setLines([
          ...lines,
          {
            points: [pos?.x, pos?.y],
            dashed: state.drawingProps.type === DrawingTypes.DASH,
          },
        ]);
      } else if (state.drawingProps.type === DrawingTypes.ARROW) {
        dispatch(
          setIsDrawing({ isDrawing: true, type: state.drawingProps.type })
        );
        const pos = stageRef.current?.getPointerPosition();
        const id = uuidv4();
        currentShapeId.current = id;
        setArrows([
          ...arrows,
          {
            id: id,
            points: [pos?.x, pos?.y, pos?.x! + 20, pos?.y! + 20],
          },
        ]);
      }
    }
  };

  const onPointerUp = () => {
    isDrawing.current = false;
    clearInterval(setInt.current);
  };

  function unScale(val: number) {
    if (stageRef.current) {
      return val / stageRef.current.scaleX();
    }
    return 0;
  }

  interface viewType extends Partial<Konva.Stage> {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  }
  interface layerType extends Partial<Konva.Layer> {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  }

  let stageRect,
    viewRect: viewType,
    fullRect: viewType,
    gridFullRect: layerType,
    gridAdjust,
    gridRect,
    gridOffset,
    newScale;
  const stepSize = 40; // set a value for the grid step gap.
  function drawLines() {
    gridLayerRef.current?.clear();
    gridLayerRef.current?.destroyChildren();
    gridLayerRef.current?.clipWidth(0); // clear any clipping

    stageRect = {
      x1: 0,
      y1: 0,
      x2: stageRef.current?.width(),
      y2: stageRef.current?.height(),
      offset: {
        x: unScale(stageRef.current?.position().x || 0),
        y: unScale(stageRef.current?.position().y || 0),
      },
    };
    viewRect = {
      x1: -stageRect?.offset?.x! || 0,
      y1: -stageRect?.offset?.y! || 0,
      x2: unScale(width) - stageRect?.offset.x!,
      y2: unScale(height) - stageRect.offset.y!,
    };
    // and find the largest rectangle that bounds both the stage and view rect.
    // This is the rect we will draw on.
    fullRect = {
      x1: Math.min(stageRect.x1, viewRect.x1),
      y1: Math.min(stageRect.y1, viewRect.y1),
      x2: Math.max(stageRect?.x2!, viewRect.x2),
      y2: Math.max(stageRect?.y2!, viewRect.y2),
    };
    gridOffset = {
      x:
        Math.ceil(unScale(stageRef.current?.position().x!) / stepSize) *
        stepSize,
      y:
        Math.ceil(unScale(stageRef.current?.position().y!) / stepSize) *
        stepSize,
    };
    gridRect = {
      x1: -gridOffset.x,
      y1: -gridOffset.y,
      x2: unScale(width) - gridOffset.x + stepSize,
      y2: unScale(height) - gridOffset.y + stepSize,
    };
    gridFullRect = {
      x1: Math.min(stageRect.x1, gridRect.x1),
      y1: Math.min(stageRect.y1, gridRect.y1),
      x2: Math.max(stageRect.x2!, gridRect.x2),
      y2: Math.max(stageRect.y2!, gridRect.y2),
    };
    drawLinesSolution4();
  }

  function drawLinesSolution4() {
    // set clip function to stop leaking lines into non-viewable space.
    gridLayerRef.current?.clip({
      x: viewRect.x1,
      y: viewRect.y1,
      width: viewRect.x2 - viewRect.x1,
      height: viewRect.y2 - viewRect.y1,
    });

    let fullRect = gridFullRect;

    const // find the x & y size of the grid
      xSize = fullRect.x2 - fullRect.x1,
      ySize = fullRect.y2 - fullRect.y1,
      // compute the number of steps required on each axis.
      xSteps = Math.round(xSize / stepSize),
      ySteps = Math.round(ySize / stepSize);

    // draw vertical lines
    for (let i = 0; i <= xSteps; i++) {
      gridLayerRef.current?.add(
        new Konva.Line({
          x: fullRect.x1 + i * stepSize,
          y: fullRect.y1,
          points: [0, 0, 0, ySize],
          stroke: "rgba(0, 0, 0, 0.2)",
          strokeWidth: 1,
          cache: true,
        })
      );
    }
    //draw Horizontal lines
    for (let i = 0; i <= ySteps; i++) {
      gridLayerRef.current?.add(
        new Konva.Line({
          x: fullRect.x1,
          y: fullRect.y1 + i * stepSize,
          points: [0, 0, xSize, 0],
          stroke: "rgba(0, 0, 0, 0.2)",
          strokeWidth: 1,
          cache: true,
          drawBorder: true,
        })
      );
    }

    gridLayerRef.current?.batchDraw();
  }
  var scaleBy = 1.01;
  let currentScale = 7;
  let scales = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  stageRef.current?.on("wheel", (e) => {
    // stop default scrolling
    e.evt.preventDefault();

    var oldScale = stageRef.current?.scaleX();
    var pointer = stageRef.current?.getPointerPosition()!;

    var mousePointTo = {
      x: (pointer?.x! - stageRef.current?.x()!) / oldScale!,
      y: (pointer?.y! - stageRef.current?.y()!) / oldScale!,
    };

    console.log("mousePointTo: ", mousePointTo);

    let direction = e.evt.deltaY > 0 ? 1 : -1;

    // when we zoom on trackpad, e.evt.ctrlKey is true
    // in that case lets revert direction
    if (e.evt.ctrlKey) {
      direction = -direction;
    }

    if (direction > 0) {
      currentScale = currentScale > 0 ? currentScale - 1 : currentScale;
    } else {
      currentScale =
        currentScale < scales.length - 1 ? currentScale + 1 : currentScale;
    }

    if (oldScale) {
      newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      if (newScale < 0.05) {
        newScale = 0.5;
      } else if (newScale > 3) {
        newScale = 3;
      }
    } else {
      newScale = 1;
    }

    stageRef.current?.scale({ x: newScale, y: newScale });

    var newPos = {
      x: pointer?.x - mousePointTo.x * newScale,
      y: pointer?.y - mousePointTo.y * newScale,
    };
    // var newPos = mousePointTo

    drawLines();
    stageRef.current?.position(newPos);

    stageRef.current?.batchDraw();
  });

  function fitStageIntoParentContainer() {
    var container = document.getElementById("#stage-container");

    // now we need to fit stage into parent container
    var containerWidth = container?.offsetWidth;

    // but we also make the full scene visible
    // so we need to scale all objects on canvas
    if (containerWidth) {
      var scale = containerWidth / width;

      stageRef.current?.width(width * scale);
      stageRef.current?.height(height * scale);
      stageRef.current?.scale({ x: scale, y: scale });
    }
  }

  function downloadURI(event: MouseEvent| TouchEvent, uri: string, name: string) {
    event.preventDefault()
    event.stopPropagation()
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    /*     document.body.removeChild(link);
    //link.remove(); */
  }

  useEffect(() => {
    var stepSize = 30;

    const xSize = stageRef.current?.width()!,
      ySize = height,
      xSteps = Math.round(xSize / stepSize),
      ySteps = Math.round(ySize / stepSize);

    // setHorizontalLines(horizontalLines);
    shadowRectangle.hide();

    drawLines();
    // adapt the stage on any window resize
    fitStageIntoParentContainer();
    window.addEventListener("resize", fitStageIntoParentContainer);

  }, [width, height, window.innerWidth, sceneList]);

  useEffect(() => {
    console.log("adding event listener");
    gridLayerRef.current?.setAttrs({fill: "red"})


    document.getElementById("printCanvas")?.addEventListener("click", (event) => {
      downloadURI(event, stageRef.current!.toDataURL(), "rahvatantsukScene");
    });

    return () => {
      console.log("Inside return");
      document
        .getElementById("printCanvas")
        ?.removeEventListener("click", (event) =>
          downloadURI(event, stageRef.current!.toDataURL(), "rahvatantsukScene")
        );
    };
  }, [])

  const handleTransformArrow = (
    event: Konva.KonvaEventObject<Event>,
    index: number
  ) => {
    const rotation = event.target.attrs.rotation;
    const updatedArrow = [...arrows];
    updatedArrow[index] = { ...arrows[index], rotation: rotation };

    setArrows(updatedArrow);
  };


  return (
    <>
      <div id="stage">
        <Stage
          ref={stageRef}
          width={width}
          height={height}
          className="konva-grid"
          onPointerMove={handlePointerMove}
          onPointerDown={onPointerDown}
          onPointerLeave={onPointerUp}
          onDragEnd={onPointerDown}
          onTouchStart={onPointerDown}
          onTouchMove={handlePointerMove}
          onTouchEnd={onPointerUp}
          onPointerUp={onPointerUp}
          style={{ backgroundColor: "#ffff" }}
          id="konva-grid"
        >
          <Layer ref={gridLayerRef} draggable={false}>
            <Group>
              {verticalLines.map((line: any) => (
                <Line key={`v${line.x}`} {...line} />
              ))}
              {horizontalLines.map((line: any) => (
                <Line key={`v${line.y}`} {...line} />
              ))}
            </Group>
          </Layer>
          <Layer
            ref={layerRef}
            onTransformEnd={() => console.log("transform end stage")}
          >
            <Rect
              x={0}
              y={0}
              fill="transparent"
              id="grid-background"
              width={window.innerWidth}
              height={window.innerHeight}
              onClick={handleClickOnCanvas}
              onTouchStart={handleClickOnCanvas}
            />
            {circles?.map((circle: any, index: number) => {
              return (
                <Circle
                  key={`circle-${index}`}
                  id={circle.id}
                  x={circle.x}
                  y={circle.y}
                  radius={circle.radius}
                  fill="transparent"
                  stroke={"black"}
                  draggable={!isDrawing.current}
                  strokeWidth={1}
                  onClick={(event) =>
                    transformerRef?.current?.nodes([event.target])
                  }
                />
              );
            })}
            {arrows?.map((arrow: any, index: number) => {
              return (
                <Arrow
                  key={`circle-${index}`}
                  id={arrow.id}
                  rotation={arrow.rotation}
                  points={arrow.points}
                  //radius={20}
                  tension={0.5}
                  lineCap="round"
                  fill="black"
                  stroke={"black"}
                  hitStrokeWidth={20}
                  strokeHitEnabled
                  draggable={!isDrawing.current}
                  strokeWidth={1}
                  onTransformEnd={(e) => handleTransformArrow(e, index - 1)}
                  onClick={(event) =>
                    transformerRef?.current?.nodes([event.target])
                  }
                />
              );
            })}
            {/*             {curvedArrows?.map((arrow: any, index: number) => {
              return (
                <Arrow
                  key={`circle-${index}`}
                  id={arrow.id}
                  rotation={arrow.rotation}
                  points={arrow.points}
                  radius={arrow.radius}
                  fill="black"
                  stroke={"black"}
                  draggable={!isDrawing.current}
                  strokeWidth={1}
                  onTransformEnd={(e) => handleTransformArrow(e, index - 1)}
                  onClick={(event) =>
                    transformerRef?.current?.nodes([event.target])
                  }
                />
              );
            })} */}
            {selectedSceneObject?.positionList?.map(
              (pos: IPositionProps, index: number) => (
                <DancerShape
                  index={index}
                  isDrawing={isDrawing.current}
                  posX={pos.posX}
                  posY={pos.posY}
                  rotation={pos.rotation}
                  gender={pos.gender}
                  blockSnapSize={blockSnapSize}
                  layerRef={layerRef}
                  transfomerRef={transformerRef}
                />
              )
            )}

            {selectedSceneObject?.drawingList?.map((line: any, i: number) => (
              <Line
                key={"line" + i}
                points={line.points}
                dash={line.dashed && [40, 10]}
                stroke="darkblue"
                strokeWidth={2}
                tension={0.5}
                lineCap="round"
                lineJoin="round"
                globalCompositeOperation={
                  line.tool === "eraser" ? "destination-out" : "source-over"
                }
                hitStrokeWidth={20}
                strokeHitEnabled
              />
            ))}

            <Transformer ref={transformerRef} flipEnabled={false} />
          </Layer>
        </Stage>
      </div>
    </>
  );
});

export default GridCanvas;
