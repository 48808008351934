import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import Layout from "./components/Layout";
import CreateDance from "./pages/CreateDance";
import ProtectedRoute from "./components/ProtectedRoute";
import { createTheme, ThemeProvider, PaletteColorOptions } from "@mui/material";
import "./index.css";
import Button, { ButtonPropsColorOverrides } from "@mui/material/Button";

declare module "@mui/material/styles" {
  interface CustomPalette {
    primary: PaletteColorOptions;
    secondary: PaletteColorOptions;
    steelBlue: PaletteColorOptions;
    violet: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    primary: true;
    secondary: true;
    steelBlue: true;
    violet: true;
  }
}

function App() {
  const { palette } = createTheme();
  const { augmentColor } = palette;
  const createColor = (mainColor: string) =>
    augmentColor({ color: { main: mainColor } });

  const lightTheme = createTheme({
    typography: {
      h2: {
        color: "#05445E",
      },
    },
    palette: {
      mode: "light",
      primary: createColor("#05445E"),
      //primary: createColor("#0C2D48"),
      //secondary: createColor("#145DA0"),
      secondary: createColor("#0C2D48"),
      steelBlue: createColor("#5C76B7"),
      violet: createColor("#BC00A3"),
      text: {
        primary: "#000",
        secondary: "#fffff",
      },
    },
  });
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: createColor("#F40B27"),
      secondary: createColor("#189AB4"),
      steelBlue: createColor("#5C76B7"),
      violet: createColor("#BC00A3"),
      text: {
        primary: "#fff",
        secondary: "#000",
      },
    },
  });
  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <Routes>
          <Route path={"/signup"} element={<Signup />} />
          <Route path={"/login"} element={<Login />} />
          <Route
            path={"/home"}
            element={
              <ProtectedRoute>
                <Layout>
                  <Home />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={"/"}
            element={
              <ProtectedRoute>
                <Layout>
                  <Home />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={"/create"}
            element={
              <ProtectedRoute>
                <Layout>
                  <CreateDance />
                </Layout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
