import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  DancerObjectProps,
  initialStateProps,
  SceneProps,
  IPositionProps,
  DrawingProps,
  DancerObjectPropsV2,
  TDrawingSliceProps,
} from "../types/DanceTypes";
import { DrawingTypes } from "../types/ShapeTypes";

const initialState: initialStateProps = {
  selectedScene: 0,
  objectCounter: 0,
  drawingProps: {
    isDrawing: false,
    type: DrawingTypes.PEN,
  },
  currentDanceId: null,
  current: {
    name: "",
    author: "",
    describer: "",
    sceneList: [],
    groupType: null,
    visibility: true,
    music: "",
    source: "",
    danceDescription: [
      {
        id: 0,
        text: "",
        measureStart: undefined,
        measureEnd: undefined,
        linkedScene: undefined,
      },
    ],
  },
};

export const danceSlice = createSlice({
  name: "dance",
  initialState,
  reducers: {
    setDance: (state, action: PayloadAction<DancerObjectPropsV2>) => {
      console.log("payload action: ", action.payload);
      return {
        ...state,
        current: {
          ...state.current,
          ...action.payload, // Merge the payload object into the current object
        },
      };
    },

    setDanceArray: (state, action: PayloadAction<IPositionProps[]>) => {
      console.log("payload ", action.payload);
      state.current.sceneList[state.selectedScene].positionList =
        action.payload;
      console.log("setDanceArray: ", action.payload);
    },

    setDancePos: (state, action: PayloadAction<IPositionProps>) => {
      if (state.current?.sceneList[state.selectedScene].positionList) {
        var foundMatch = false;
        var index = 0;

        for (
          let i = 0;
          i < state.current.sceneList[state.selectedScene].positionList.length;
          i++
        ) {
          if (
            action.payload.id ===
            state.current.sceneList[state.selectedScene].positionList[i].id
          ) {
            //Kui listis on objekt, mis on sama mis actionis, siis uuendame olemasolevat positsiooni

            foundMatch = true;
            index = i;
          }
        }

        if (foundMatch) {
          console.log("uuendame olemasolevat objekti: ", action.payload);
          state.current.sceneList[state.selectedScene].positionList[index] =
            action.payload;
        } else {
          console.log("Lisame uue objekti ", action.payload);

          //Kui sellist objekti ei ole, siis lisame uue
          state.current.sceneList[state.selectedScene].positionList.push(
            action.payload
          );
        }
      }
    },

    setSceneList: (state, action: PayloadAction<SceneProps>) => {
      state.current.sceneList.push(action.payload);
      console.log(" setSceneLit: ", action.payload);
    },
    addDanceElem: (state, action: PayloadAction<IPositionProps>) => {
      state.current.sceneList[state.selectedScene].positionList.push(
        action.payload
      );
    },

    deleteScene: (state, action: PayloadAction<number>) => {
      const sceneList = state.current.sceneList;
      const id = action.payload;

      sceneList.splice(id, 1);
      state.current.sceneList = sceneList;
    },
    setDanceName: (state, action: PayloadAction<number>) => {
      state.selectedScene = action.payload;
    },
    setDanceId: (state, action: PayloadAction<number>) => {
      state.selectedScene = action.payload;
    },
    setSelectedScene: (state, action: PayloadAction<number>) => {
      state.selectedScene = action.payload;
    },
    resetState: (state) => {
      state = initialState;
    },
    setObjectCounter: (state, action: PayloadAction<number>) => {
      state.objectCounter = action.payload;
    },

    /* DRAWING SLICES*/
    setIsDrawing: (state, action: PayloadAction<TDrawingSliceProps>) => {
      state.drawingProps = action.payload;
    },
    setDrawingList: (state, action: PayloadAction<DrawingProps[]>) => {
      state.current.sceneList[state.selectedScene].drawingList = action.payload;
    },
  },
});

export const {
  setDance,
  setDancePos,
  setDanceArray,
  setSelectedScene,
  setSceneList,
  setIsDrawing,
  deleteScene,
  resetState,
  setObjectCounter,
  addDanceElem,
  setDrawingList,
} = danceSlice.actions;

export default danceSlice.reducer;
