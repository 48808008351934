import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { MouseEventHandler } from "react";
import "../styles/Button.scss";
interface ActionButtonProps {
  onClick?: MouseEventHandler | undefined;
  variant: "text" | "contained" | "outlined";
  text: string;
  icon?: any;
  isLoading?: boolean;
  id?: string;
}

const ActionButton = ({
  onClick,
  variant,
  text,
  icon,
  isLoading,
  id,
}: ActionButtonProps) => {
  return (
    <>
      {text ? (
        <Button
          className={"default-button"}
          disableRipple
          color={"primary"}
          variant={variant}
          size="small"
          onClick={onClick}
          endIcon={icon}
          disabled={isLoading}
          sx={{ margin: "5px" }}
          id={id}
        >
          {isLoading ? <CircularProgress size={20} className="loader" /> : text}
        </Button>
      ) : (
        <IconButton
          disableRipple
          onClick={onClick}
          disabled={isLoading}
          id={id}
          size="small"
          color={"inherit"}
          sx={{ margin: "5px" }}
        >
          {isLoading ? <CircularProgress /> : icon}
        </IconButton>
      )}
    </>
  );
};

export default ActionButton;
