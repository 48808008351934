import axios from "axios";
import { DancerObjectProps } from "../types/DanceTypes";
import { DanceApi } from "./Endpoints";
import { headers } from "../controllers/Endpoints";
import { detectUrl } from "../helpers/helpers";

export const updateCurrentDance = (
  danceId: number,
  body: DancerObjectProps
) => {
  axios
    .put(detectUrl + DanceApi.UPDATE + danceId, body, { headers: headers })
    .then((res) => {
      console.log("successfully updated dance", res);
    })
    .catch((e) => {
      console.log("Error updating current dance", e);
    });
};
