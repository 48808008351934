import React, { FC } from "react";
import Navbar from "./Navbar";
import MainWrapper from "./MainWrapper";
interface LayoutProps {
  children: React.FC | JSX.Element;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Navbar />
      <MainWrapper>{children}</MainWrapper>
    </>
  );
};

export default Layout;
