import { Button, ButtonGroup, Divider, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import "../styles/FilterRow.scss";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Rotate90DegreesCcw } from "@mui/icons-material";
import ActionButton from "./ActionButton";

interface FilterRowProps {
  selected: number[];
  onFilter: (selected: number) => void;
}

function FilterRow({ selected, onFilter }: FilterRowProps) {
  const [sortByName, setSortByName] = useState("asc");

  const setSort = () => {
    const direction = sortByName === "asc" ? "desc" : "asc";
    setSortByName(direction);
  };

  const setSelected = (event: any) => {
    onFilter(Number(event.target.value));
  };

  return (
    <>
      <Stack
        direction={"row"}
        className={"container"}
        minWidth={"100%"}
        gap={1}
      >
        <Button onClick={setSort} variant="text">
          Name
          <ArrowDownwardIcon
            className={"buttonIcon"}
            sx={
              sortByName === "asc"
                ? { transform: "rotate(180deg)" }
                : { transform: "rotate(0deg)" }
            }
          />
        </Button>
        <Button
          id="myDances"
          value={0}
          onClick={setSelected}
          variant={selected.includes(0) ? "text" : "text"}
          className={selected.includes(0) ? "filterrow_button--selected" : ""}
        >
          Minu tantsud
        </Button>
        <Button
          value={1}
          onClick={setSelected}
          variant={selected.includes(1) ? "text" : "text"}
          className={selected.includes(1) ? "filterrow_button--selected" : ""}
        >
          Autoritantsud
        </Button>
      </Stack>
    </>
  );
}

export default FilterRow;
