import React from "react";
import { Navigate } from "react-router-dom";
type Props = {
  children: JSX.Element;
};

const ProtectedRoute = ({ children }: Props) => {
  const jwt = window.sessionStorage.getItem("rahvatantsukToken");
  return jwt ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
