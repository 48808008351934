import { Grid, Typography } from "@mui/material";
import React from "react";

interface ControlsProps {
  children: React.ReactNode;
  title?: string | null; //ReactI18NextChildren
  sx?: object;
  alignTitle?: object;
  className?: string;
}

const ControlsProps: React.FC<ControlsProps> = ({
  children,
  title,
  sx,
  alignTitle,
  className,
}) => {
  return (
    <Grid
      sx={sx}
      item
      padding={"10px 20px"}
      justifyContent={"space-evenly"}
      flexWrap={"wrap"}
    >
      <Typography
        variant="h2"
        fontWeight={"bold"}
        fontSize={14}
        sx={alignTitle}
      >
        {title && title}
      </Typography>
      <Grid className={className}>{children}</Grid>
    </Grid>
  );
};

export default ControlsProps;
