import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locales/en.json";
import et from "../locales/et.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      et: {
        translation: et,
      },
      en: {
        translation: en,
      },
    },
    lng: "et", // if you're using a language detector, do not define the lng option
    fallbackLng: "et",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
