import {
  Avatar,
  Grid,
  Paper,
  TextField,
  Box,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LockIcon from "@mui/icons-material/Lock";
import { Link, useNavigate } from "react-router-dom";
import "../styles/Login.scss";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setUserData } from "../slices/userSlice";
import { detectUrl } from "../helpers/helpers";
import { User } from "../controllers/Endpoints";
import { setAuthToken } from "../helpers/setAuthToken";

function Login() {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const styledPaper = {
    padding: 20,
    height: "50vh",
    width: 500,
    margin: "80px auto",
    justifyContent: "center",
    alignContent: "center",
  };
  const styledBackground = { backgroundColor: "transparent" };
  const styledAvatar = { backgroundColor: "green" };
  const styledBox = {
    display: "flex",
    marginTop: "30px",
    alignContent: "center",
    justifyContent: "center",
  };
  const { t } = useTranslation();

  const updUser = (e: any) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const loginUser = () => {
    const url = detectUrl + User.LOGIN;

    axios
      .post(
        url,
        {
          email: user.email,
          password: user.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        if (response) {
          window.sessionStorage.setItem("rahvatantsukToken", response.data.jwt);
          setAuthToken(response.data.jwt);
          navigate("/home");
          const data = {
            id: response.data.accountId,
            name: response.data.name,
          };
          dispatch(setUserData(data));
        }
      })
      .catch((err) => {
        console.log(err);
        window.sessionStorage.removeItem("rahvatantsukToken");
      });
  };

  useEffect(() => {
    document.body.classList.add("loginBackground");
    return () => {
      document.body.classList.remove("loginBackground");
    };
  }, []);

  return (
    <Paper id="paper" elevation={0} style={styledBackground}>
      <Grid id="base">
        <Paper style={styledPaper} elevation={5}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Avatar style={styledAvatar}>
              <LockIcon />
            </Avatar>
            <h2>{t("LOGIN.TITLE-LOGIN")}</h2>
          </Grid>
          <Box>
            <TextField
              sx={{ marginBottom: "15px" }}
              label={t("LOGIN.EMAIL")}
              value={user.email}
              onChange={updUser}
              name={"email"}
              fullWidth
              required
            ></TextField>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {t("LOGIN.PASSWORD")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                onChange={updUser}
                value={user.password}
                name={"password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={t("LOGIN.PASSWORD")}
              />
            </FormControl>

            <Box style={styledBox} flexDirection="column">
              <Button
                sx={{ marginBottom: "10px" }}
                onClick={loginUser}
                size="large"
                variant="contained"
              >
                {t("LOGIN.LOGIN")}
              </Button>

              <Button
                size="small"
                variant="text"
                to={"/signup"}
                component={Link}
              >
                {t("LOGIN.REGISTER")}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Paper>
  );
}

export default Login;
