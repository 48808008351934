import React, { useState } from "react";
import {
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { setDance } from "../slices/danceSlice";
import "../styles/DescriptionDrawer.scss";
import { motion } from "framer-motion";
import {
  DancerObjectPropsV2,
  SceneProps,
  TDanceDescription,
} from "../types/DanceTypes";
import { Add, WidthFull } from "@mui/icons-material";
import Separator from "./Separator";

interface DescriptionDrawerProps {
  open: boolean;
  onClose: (closeDrawer: boolean) => void;
}

const DescriptionDrawer = ({ open, onClose }: DescriptionDrawerProps) => {
  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.dance.current);
  const descriptions = useSelector(
    (state: any) => state.dance.current.danceDescription
  );

  const toggleDrawer = () => {
    onClose(false);
  };

  const addNewDescription = () => {
    const newObj = {
      ...state,
      danceDescription: [
        ...descriptions,
        {
          id: descriptions.length + 1,
          text: "",
          measureStart: undefined,
          measureEnd: undefined,
          linkedScene: undefined,
        },
      ],
    } as DancerObjectPropsV2;
    dispatch(setDance(newObj));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newObj = {
      ...state,
      description: event.target.value,
    };
    dispatch(setDance(newObj));
  };

  return (
    <motion.div className="description__wrapper">
      <Drawer
        open={open}
        variant={"persistent"}
        anchor={"right"}
        ModalProps={{
          keepMounted: false,
        }}
      >
        <div className="description__drawer">
          <IconButton onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
          <div className="description__content">
            {descriptions.map(
              (description: TDanceDescription, index: number) => {
                return (
                  <>
                    <div className="description__content-block">
                      <p className="text--size-md">Joonis {index}</p>
                      <p className="text--size-sm">Taktivahemik</p>
                      <div className="description__measure">
                        <TextField
                          label="Algus"
                          size="small"
                          variant="outlined"
                          margin="dense"
                          type="number"
                          sx={{ fontSize: "9", width: "90px" }}
                          defaultValue={description.measureStart}
                        />
                        <TextField
                          label="Lõpp"
                          size="small"
                          variant="outlined"
                          margin="dense"
                          type="number"
                          sx={{ fontSize: "9", width: "90px" }}
                          defaultValue={description.measureEnd}
                        />
                      </div>
                      <Separator size="sm" />
                      <p className="text--size-sm">Kirjeldus</p>
                      <Separator size="sm" />
                      <TextareaAutosize
                        minRows={2}
                        onChange={handleInputChange}
                        placeholder="Tantsukirjeldus..."
                        style={{ width: "100%", whiteSpace: "pre-wrap" }}
                        defaultValue={description.text}
                      />
                      <FormControl fullWidth margin="dense">
                        <InputLabel id="demo-simple-select-label">
                          Seotud joonis
                        </InputLabel>
                        <Select
                          size="small"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Seotud joonis"
                          className="description__select"
                          placeholder="Vali seotud joonis..."
                          variant="outlined"
                        >
                          {state.sceneList.map(
                            (scene: SceneProps, index: number) => (
                              <MenuItem
                                value={scene.sceneId}
                                key={index}
                              >{`Joonis ${scene.sceneId}`}</MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </div>
                  </>
                );
              }
            )}
            <IconButton
              onClick={addNewDescription}
              className="description__add"
            >
              <Add />
            </IconButton>
          </div>
        </div>
      </Drawer>
    </motion.div>
  );
};

export default DescriptionDrawer;
