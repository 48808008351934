import { motion } from "framer-motion";
import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../styles/Carousel.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedScene,
  setSceneList,
  deleteScene,
} from "../slices/danceSlice";
import classNames from "classnames";
import { DrawingProps, SceneProps } from "../types/DanceTypes";
import Konva from "konva";
import { TShapesList } from "../types/ShapeTypes";
interface BlockProps {
  sceneName: string;
  sceneId: number;
  positionList: PositionProps[];
  drawingList: TShapesList[];
  shapesList: TShapesList[];
}
interface PositionProps {
  posX: number;
  posY: number;
  gender: "MALE" | "FEMALE" | "MIXED";
  constraints?: any;
}
interface CarouselProps {
  blocks: BlockProps[];
  setBlocks: (blocks: BlockProps) => void;
  transformer?: React.RefObject<Konva.Transformer>;
}

const Carousel: React.FC<CarouselProps> = ({
  blocks,
  setBlocks,
  transformer,
}) => {
  const carousel = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>(0);

  const lastIdUrl = new URLSearchParams(window.location.search).get("id");
  const lastId = lastIdUrl ? blocks[blocks.length - 1]?.sceneId : 0;
  var [danceBlockCounter, setDanceBlockCounter] = useState(lastId);
  var [selectedBlock, setSelectedBlock] = useState(0);
  const prevScenePos = useSelector(
    (state: any) =>
      state.dance?.current?.sceneList[danceBlockCounter - 1]?.positionList
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleAddBlock = () => {
    var newDanceObj: BlockProps = {
      sceneName: "",
      sceneId: danceBlockCounter + 1,
      positionList: danceBlockCounter > 0 ? prevScenePos : [],
      drawingList: [],
      shapesList: [],
    };

    setBlocks(newDanceObj);
    dispatch(setSceneList(newDanceObj));
    setDanceBlockCounter(danceBlockCounter + 1);
  };

  const handleRemoveBlock = (id: number) => {
    const filteredArray = blocks.filter((block) => block.sceneId !== 1);
    console.log(
      "filtered arr: ",
      blocks.filter((block) => block.sceneId !== 1)
    );

    dispatch(deleteScene(id));
  };

  const handleSelectScene = (id: number) => {
    dispatch(setSelectedScene(id - 1));
    setSelectedBlock(id);
  };

  const handleDeleteScene = (e: any, id: number) => {
    e.preventDefault();
    if (window.confirm("Kas soovid selle joonise kustutada?"))
      dispatch(deleteScene(id - 1));
  };

  useEffect(() => {
    const current = carousel?.current;
    const scrollWidth = current?.scrollWidth as number;
    const offsetWidth = current?.offsetWidth as number;
    let carouselWidth = scrollWidth - offsetWidth;

    if (scrollWidth > offsetWidth) {
      setWidth(-carouselWidth);
    } else {
      setWidth(0);
    }
  }, [blocks]);

  useEffect(function () {
    if (!window.location.search) {
      handleAddBlock();
    }
    handleSelectScene(1);
  }, []);

  return (
    <>
      <motion.div
        ref={carousel}
        style={{ position: "relative" }}
        className="carousel dance-block-container"
      >
        <motion.div
          className="inner-carousel"
          drag="x"
          dragConstraints={{ right: 0, left: width }}
          dragTransition={{ bounceStiffness: 1000, bounceDamping: 20 }}
        >
          {blocks.map((block: SceneProps, index: number) => {
            return (
              <>
                <motion.div
                  key={index}
                  id={JSON.stringify(block.sceneId - 1)}
                  onClick={(event) => {
                    handleSelectScene(block.sceneId);
                  }}
                  data-id={block.sceneId}
                  className={classNames("dance-block", {
                    selected: selectedBlock === block.sceneId,
                  })}
                >
                  <IconButton
                    key={index}
                    disableRipple
                    className={"close-icon"}
                    onClick={(e) => handleDeleteScene(e, block.sceneId)}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography key={block.id}>
                    {t("DANCE.PATTERN") + " " + block.sceneId}
                  </Typography>
                </motion.div>
              </>
            );
          })}
          <motion.div
            key={"add-new"}
            onClick={handleAddBlock}
            className="dance-block add"
          >
            +
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Carousel;
