import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "../styles/DanceCard.scss";
import { Link } from "react-router-dom";
import Rahvusmuster from "../assets/rahvusmuster.jpeg";
import { Star, StarBorderOutlined } from "@mui/icons-material";
import { t } from "i18next";

interface DanceCardProps {
  picture?: string;
  name: string;
  id: string;
  description?: string;
  author: string;
  describer: string;
  year?: number;
}

const DanceCard = ({
  picture,
  name,
  id,
  description,
  author,
  describer,
  year,
}: DanceCardProps) => {
  const [favorite, setFavorite] = useState(false);

  const handleAddFavorite = () => {
    setFavorite(!favorite);
  };
  return (
    <>
      <Grid item id={id}>
        <Card
          className={"card_container"}
          elevation={1}
          component={Link}
          to={"/create?id=" + id}
        >
          <CardMedia component="img" height="40" image={Rahvusmuster} />
          <CardContent className={"card_content"}>
            <Typography variant="h5">
              {name}
              <CardActions className={"card_actions"}>
                {favorite ? (
                  <Star onClick={handleAddFavorite} />
                ) : (
                  <StarBorderOutlined onClick={handleAddFavorite} />
                )}
              </CardActions>
            </Typography>
            <br></br>
            {author ? (
              <Typography color="text.secondary">
                {t("DANCE.AUTHOR")}: {author}
              </Typography>
            ) : (
              <a>Autor puudub</a>
            )}
            {describer || describer !== author ? (
              <Typography color="text.secondary">
                {t("DANCE.DESCRIBER")}: {describer}
              </Typography>
            ) : (
              ""
            )}
            <Typography color="text.secondary">{year}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default DanceCard;
