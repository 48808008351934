import { Grid, Skeleton } from "@mui/material";
import React from "react";
import "./DanceCard.scss";
import DanceCard from "../DanceCard";

const DanceCardSkeleton = () => {
  return (
    <>
      <Grid item md={3} sm={4} padding={0}>
        <div className="skeleton__wrapper">
          <Skeleton
            variant="rectangular"
            animation="wave"
            className="skeleton__img"
          />
          <div className="skeleton__content-wrapper">
            <Skeleton
              variant="text"
              animation="wave"
              width={200}
              height={30}
              className="skeleton__content"
            />
            <Skeleton
              variant="text"
              animation="wave"
              className="skeleton__content"
              width={160}
              height={20}
            />
            <Skeleton
              variant="text"
              animation="wave"
              className="skeleton__content"
              width={210}
              height={20}
            />
            <Skeleton
              variant="text"
              animation="wave"
              className="skeleton__content"
              width={100}
              height={20}
            />
          </div>
        </div>
      </Grid>
    </>
  );
};

export default DanceCardSkeleton;
