import {
  Avatar,
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "../styles/Login.scss";
import { useTranslation } from "react-i18next";
import { detectUrl } from "../helpers/helpers";

function Signup() {
  const [input, setInput] = React.useState({
    email: "",
    lastname: "",
    firstname: "",
    password: "",
    role: "",
  });
  const { t } = useTranslation();

  const styledPaper = {
    padding: 20,
    height: "auto",
    width: 500,
    margin: "80px auto",
    justifyContent: "center",
    alignContent: "center",
  };
  const styledAvatar = { backgroundColor: "green" };
  const styledBox = {
    display: "flex",
    marginTop: "30px",
    alignContent: "center",
    justifyContent: "center",
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput({ ...input, [event?.target?.id]: event?.target.value });
    console.log(input);
  };

  const body = {
    firstName: input.firstname,
    lastName: input.lastname,
    email: input.email,
    password: input.password,
  };

  const signUp = () => {
    fetch(detectUrl + "/register", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(body),
    });
  };

  useEffect(() => {
    document.body.classList.add("loginBackground");
    return () => {
      document.body.classList.remove("loginBackground");
    };
  }, []);
  return (
    <Grid>
      <Paper style={styledPaper} elevation={5}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Avatar style={styledAvatar}>
            <LockIcon />
          </Avatar>
          <h2>{t("LOGIN.TITLE-REGISTER")}</h2>
        </Grid>
        <Box>
          <TextField
            type="text"
            margin="normal"
            label={t("LOGIN.FIRST-NAME")}
            id="firstname"
            onChange={handleInputChange}
            fullWidth
            required
          ></TextField>
          <TextField
            margin="normal"
            label={t("LOGIN.LAST-NAME")}
            id="lastname"
            onChange={handleInputChange}
            fullWidth
            required
          />
          <TextField
            margin="normal"
            label={t("LOGIN.EMAIL")}
            id="email"
            onChange={handleInputChange}
            type="email"
            fullWidth
            required
          />
          <TextField
            margin="normal"
            label={t("LOGIN.PASSWORD")}
            id="password"
            onChange={handleInputChange}
            fullWidth
            required
          />
          <InputLabel id="role-input">{t("LOGIN.ROLE")}</InputLabel>
          <Select
            labelId="role-input"
            id="role"
            value={input.role}
            fullWidth
            required
          >
            <MenuItem value={""}>
              <em>NONE</em>
            </MenuItem>
            <MenuItem value={"student"}>{t("LOGIN.ROLESTUDENT")}</MenuItem>
            <MenuItem value={"teacher"}>{t("LOGIN.ROLETEACHER")}</MenuItem>
          </Select>
          <Box style={styledBox} flexDirection="column">
            <Button
              sx={{ marginBottom: "10px" }}
              onClick={signUp}
              size="large"
              variant="contained"
            >
              {t("LOGIN.REGISTER")}
            </Button>

            <Button
              startIcon={<ArrowBackIosNewIcon />}
              size="small"
              variant="text"
              to={"/login"}
              component={Link}
            >
              {t("LOGIN.BACK-TO-LOGIN")}
            </Button>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
}

export default Signup;
