export enum DanceApi {
  SAVE = "/dance/save",
  GET_DANCE = "/dance/getById/",
  UPDATE = "/dance/update/",
  GET_ALL = "/dance/getPublicDances",
  DELETE = "/dance/delete?danceId=",
  GET_BY_ACCOUNT = "/dance/getByAccount",
  SEARCH = "/dance/search",
}
export enum User {
  LOGIN = "/login",
  SIGNUP = "/signup",
}

export const headers = {
  rahvatantsukToken: window.sessionStorage.getItem("rahvatantsukToken"),
};
