import React from "react";

const MixedDancers = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      viewBox="0 0 30 30"
      height="40"
      version="1.0"
    >
      <defs>
        <clipPath id="a">
          <path d="M14 4h15.516v24H14Zm0 0" />
        </clipPath>
        <clipPath id="b">
          <path d="M8 4h21.516v24H8Zm0 0" />
        </clipPath>
        <clipPath id="c">
          <path d="M1.934 12.098h13.062v7.984H1.934Zm0 0" />
        </clipPath>
      </defs>
      <g clip-path="url(#a)">
        <path
          stroke-linecap="round"
          fill="none"
          d="m22.152 19.027 5.164-6.52"
          stroke="#000"
          stroke-width="1.414"
        />
      </g>
      <g clip-path="url(#b)">
        <path
          stroke-linecap="round"
          fill="none"
          d="m16.883 12.512 5.203 6.586"
          stroke="#000"
          stroke-width="1.414"
        />
      </g>
      <g clip-path="url(#c)">
        <path d="M8.465 20.082a6.53 6.53 0 0 0 6.531-6.531 6.532 6.532 0 1 0-13.062 0 6.53 6.53 0 0 0 6.53 6.531m0-11.758a5.224 5.224 0 0 1 5.226 5.227 5.223 5.223 0 0 1-5.226 5.222 5.223 5.223 0 0 1-5.227-5.222c0-2.89 2.34-5.227 5.227-5.227m0 0" />
      </g>
    </svg>
  );
};

export default MixedDancers;
