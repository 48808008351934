import { configureStore } from "@reduxjs/toolkit";
import danceReducer from "../slices/danceSlice";
import userReducer from "../slices/userSlice";
import gridSlice from "../slices/gridSlice";

export const store = configureStore({
  reducer: {
    dance: danceReducer,
    user: userReducer,
    grid: gridSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
