import React, { Profiler } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { Provider, createDispatchHook } from "react-redux";
import { store } from "./store/store";
import "./utils/i18n.js";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const onRender = (
  id: string,
  phase: "mount" | "update",
  actualDuration: number,
  baseDuration: number,
  startTime: number,
  commitTime: number
) => {
  console.log(id, phase, actualDuration, baseDuration, startTime, commitTime);
};
root.render(
  // <React.StrictMode> {/*see põhjustab dev keskkonnas double renderimist domis*/}
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);
