import { Divider, Grid, Skeleton, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import DanceCard from "../components/DanceCard";
import FilterRow from "../components/FilterRow";
import "../styles/Home.scss";
import axios from "axios";
import { Simulate } from "react-dom/test-utils";
import select = Simulate.select;
import { t } from "i18next";
import { setTimeout } from "timers/promises";
import { detectUrl } from "../helpers/helpers";
import PaginationComponent from "../components/Pagination";
import { DanceApi, headers } from "../controllers/Endpoints";
import MainWrapper from "../components/MainWrapper";
import DanceCardSkeleton from "../components/Skeletons/DanceCardSkeleton";

function Home() {
  const [dances, setDances] = useState<DanceCardProps[] | null>(null);
  const [filteredDances, setFilteredDances] = useState<DanceCardProps[] | null>(
    null
  );
  const [personalDances, setPersonalDances] = useState<DanceCardProps[] | null>(
    null
  );
  const [heritageDances, setHeritageDances] = useState<DanceCardProps[] | null>(
    null
  );
  const [selectedTypes, setSelectedTypes] = useState<number[]>([1]);

  interface DanceCardProps {
    accountId?: number;
    picture?: string;
    name: string;
    danceId: string;
    description?: string;
    author: string;
    describer: string;
    visibility: boolean;
    year?: number;
  }

  const fetchDanceData = async () => {
    try {
      const response = await axios.post(
        detectUrl + DanceApi.GET_ALL,
        {
          danceType: "FOLKDANCES",
          userDances: true,
          page: 0,
          size: 10,
        },
        { headers }
      );
      const newData = response.data.map((obj: DanceCardProps) => ({
        accountId: obj.accountId,
        danceId: obj.danceId,
        name: obj.name,
        author: obj.author,
        describer: obj.describer,
        year: obj.year,
        visibility: obj.visibility,
      }));
      console.log(response);
      setDances(newData);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const filterData = () => {
    const accountId = window.sessionStorage.getItem("clientId");

    const foundPersonalDances = selectedTypes.includes(0)
      ? dances?.filter((dance) => dance.accountId === Number(accountId))
      : null;

    const foundHeritageDances = selectedTypes.includes(1)
      ? dances?.filter(
          (dance) =>
            (dance.visibility === true || dance.visibility === null) &&
            dance.accountId !== Number(accountId)
        )
      : null;

    if (foundPersonalDances !== undefined) {
      setPersonalDances(foundPersonalDances);
    } else {
      setPersonalDances(null);
    }

    if (foundHeritageDances !== undefined) {
      setHeritageDances(foundHeritageDances);
    } else {
      setHeritageDances(null);
    }
  };

  const handleFilter = (index: number) => {
    if (selectedTypes.includes(index)) {
      setSelectedTypes(
        selectedTypes.filter((selectedType) => selectedType !== index)
      );
    } else {
      setSelectedTypes([...selectedTypes, index]);
    }
  };

  useEffect(() => {
    fetchDanceData();
    window.setTimeout(() => {
      document.getElementById("myDances")?.click();
    }, 100);
  }, []);

  useEffect(() => {
    filterData();
  }, [selectedTypes]);

  useEffect(() => {
    if (personalDances && heritageDances) {
      setFilteredDances(personalDances.concat(heritageDances));
    } else if (personalDances) {
      setFilteredDances(personalDances);
    } else if (heritageDances) {
      setFilteredDances(heritageDances);
    } else {
      setFilteredDances([]);
    }
  }, [personalDances, heritageDances]);

  const generateSkeletons = (amount: number) => {
    const skeletons = [];
    for (let i = 0; i < amount; i++) {
      skeletons.push(<DanceCardSkeleton />);
    }

    return skeletons;
  };

  return (
    <>
      <Grid
        container
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <FilterRow selected={selectedTypes} onFilter={handleFilter} />
        <Grid
          container
          className={"home-grid"}
          minHeight={"100%"}
          direction={"row"}
          spacing={2}
        >
          {dances
            ? generateSkeletons(8)
            : filteredDances?.map((dance: DanceCardProps) => {
                return (
                  <Grid item md={3} sm={4}>
                    <DanceCard
                      name={dance.name}
                      id={dance.danceId}
                      author={dance.author}
                      describer={dance.describer}
                      year={2023}
                      key={dance.danceId}
                    />
                  </Grid>
                );
              })}
        </Grid>
        <PaginationComponent count={10} />
      </Grid>
    </>
  );
}

export default Home;
