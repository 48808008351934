import { Grid, Stack, Typography, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Input from "@mui/material/Input";
import "../styles/SideMenu.scss";
import { useSelector } from "react-redux";

interface SideMenuProps {
  name: string;
  author?: string;
  music?: string;
  onChange: (fieldName: string, value: string) => void;
}

function SideMenu({ name, author, music, onChange }: SideMenuProps) {
  const { t } = useTranslation();
  const state = useSelector((state: any) => state.dance.current);

  const handleNameChange = (event: any) => {
    onChange("name", event.target.value);
  };

  const handleAuthorChange = (event: any) => {
    onChange("author", event.target.value);
  };

  const handleMusicChange = (event: any) => {
    onChange("music", event.target.value);
  };
  const handleSourceChange = (event: any) => {
    onChange("source", event.target.value);
  };

  return (
    <>
      <Grid item minWidth={300} className="side__menu--wrapper">
        <Stack direction={"column"} spacing={4}>
          <TextField
            id="outlined-basic"
            label={t("DANCE.NAME")}
            required
            variant="standard"
            value={state.name}
            onChange={handleNameChange}
            margin="dense"
          />
          <TextField
            id="outlined-basic"
            label={t("DANCE.AUTHOR")}
            variant="standard"
            required
            value={state.author}
            onChange={handleAuthorChange}
            margin="dense"
          />
          <TextField
            id="outlined-basic"
            label={t("DANCE.MUSIC")}
            variant="standard"
            value={state.music}
            onChange={handleMusicChange}
            margin="dense"
          />
          <TextField
            id="outlined-basic"
            label={t("DANCE.SOURCE")}
            variant="standard"
            value={state.source}
            onChange={handleSourceChange}
            margin="dense"
          />
        </Stack>
      </Grid>
    </>
  );
}

export default SideMenu;
