export enum DrawingTypes {
  PEN = "PEN",
  DASH = "DASH",
  CIRCLE = "CIRCLE",
  ERASER = "ERASER",
  ARROW = "ARROW",
  ROUNDED_ARROW = "ROUNDED_ARROW",
  DELETE = "DELETE"
}

export type TShapesList = {
  type?: DrawingTypes;
  points: Array<number>;
};
