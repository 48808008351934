import classNames from "classnames";
import React from "react";
import "../styles/Separator.scss";

export type TSeparatorProps = {
  size: "sm" | "md" | "lg";
};

const Separator = ({ size = "sm" }: TSeparatorProps) => {
  return (
    <div
      className={classNames("separator", { [`separator--size-${size}`]: true })}
    ></div>
  );
};

export default Separator;
