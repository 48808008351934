import { Grid } from "@mui/material";
import "../styles/Main.scss";

const MainWrapper = ({children}: any) => {
  return (
    <>
      <Grid
        container
        className="wrapper"
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <>{children}</>
      </Grid>
    </>
  );
};

export default MainWrapper;
