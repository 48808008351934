import React from "react";

const MaleDancer = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      viewBox="0 0 30 30"
      height="28"
      version="1.0"
    >
      <defs>
        <clipPath id="a">
          <path d="M2.043 6.688h26.129v15.964H2.043Zm0 0" />
        </clipPath>
      </defs>
      <g clip-path="url(#a)">
        <path d="M15.11 6.688c-7.22 0-13.067 5.843-13.067 13.062s5.848 13.066 13.066 13.066c7.22 0 13.063-5.847 13.063-13.066S22.328 6.688 15.109 6.688m0 23.515c-5.774 0-10.454-4.676-10.454-10.453 0-5.773 4.68-10.45 10.453-10.45 5.774 0 10.45 4.677 10.45 10.45 0 5.777-4.676 10.453-10.45 10.453m0 0" />
      </g>
    </svg>
  );
};

export default MaleDancer;
